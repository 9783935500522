<template>
  <div>
    <van-nav-bar title="快捷入口" left-text="返回" left-arrow @click-left="back()" />
  </div>
  <div class="d_form">
    <van-cell-group inset>
      <div class="flex scan">
        <div style="width: 88%;"><van-field v-model="dm" label="地码" placeholder="扫码或者输入" /></div>
        <div><van-icon name="scan" size="22" /></div>
      </div>
      <van-field v-model="statusName" is-link readonly name="picker" label="库位状态" placeholder="点击选择" @click="statusPicker = true"/>
      <van-popup v-model:show="statusPicker" position="bottom">
        <van-picker :columns="columns" :columns-field-names="customFieldName" @confirm="statusConfirm" @cancel="statusPicker = false" />
      </van-popup>
      <div class="flex scan">
        <div style="width: 88%;"><van-field v-model="jig" label="工装号" placeholder="扫码或者输入" /></div>
        <div><van-icon name="scan" size="22" /></div>
      </div>
      <van-field v-model="jigTypeName" is-link readonly name="picker" label="工装类型" placeholder="点击选择" @click="jigPicker = true"/>
      <van-popup v-model:show="jigPicker" position="bottom">
        <van-picker :columns="jigColumms" :columns-field-names="customFieldName" @confirm="jigTypeConfirm" @cancel="jigPicker = false" />
      </van-popup>
    </van-cell-group>
    <div style="height: 80px"></div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router"
import { Toast } from 'vant';
import { ref } from 'vue'
const router = useRouter()
const statusName = ref('')
const jigTypeName = ref('')
const activeName = ref(1)
const statusPicker = ref(false)
const jigPicker = ref(false)
const jigType = ref('')
const checked = ref([]);
const current_warehouse = ref('原材料区')
const current_location= ref('库区')
const current_status= ref('有业务时显示业务名称/无业务显示暂无')
const checkboxRefs = ref([]);
const toggle = (index) => {
  checkboxRefs.value[index].toggle();
};
const columns = [
  { id: 1, value: '状态1' },
  { id: 2, value: '状态2' },
  { id: 3, value: '状态3' }
]
const jigColumms = [
  { id: 1, value: '类型1' },
  { id: 2, value: '类型2' },
  { id: 3, value: '类型3' }
]
const list = ref([
  {
    num: 1,
    name: '业务名称1',
    start: '仓库1',
    end: '仓库2',
    option: '库位状态',
    way: '拉动',
  },
  {
    num: 2,
    name: '业务名称2',
    start: '仓库1',
    end: '仓库2',
    option: '库位状态',
    way: '推动',
  },
  {
    num: 3,
    name: '业务名称1',
    start: '仓库1',
    end: '仓库2',
    option: '订单',
    way: '拉动',
  },
  {
    num: 4,
    name: '业务名称1',
    start: '仓库1',
    end: '仓库2',
    option: '订单',
    way: '推动',
  },
  {
    num: 5,
    name: '业务名称1',
    start: '仓库1',
    end: '仓库2',
    option: '扫描',
    way: '拉动',
  },
  {
    num: 6,
    name: '业务名称1',
    start: '仓库1',
    end: '仓库2',
    option: '扫描',
    way: '拉动',
  },
  {
    num: 7,
    name: '业务名称1',
    start: '仓库1',
    end: '仓库2',
    option: '扫描',
    way: '拉动',
  },
  {
    num: 8,
    name: '业务名称1',
    start: '仓库1',
    end: '仓库2',
    option: '扫描',
    way: '推动',
  }
]);

const listA= ref([
  { name: '左后视镜总承',
    code: 'hsj-50',
    number: '10',
    status: '有货',
    jig: '01',
    lot: 1,
    id: 1
  },
  { name: '左后视镜总承',
    code: 'hsj-50',
    number: '10',
    status: '有货',
    jig: '01',
    lot: 1,
    id: 2
  }
])
const list1= ref([
  { name: '库位状态拉动',
    type: '拉动',
    start: '库位状态拉动',
    end: '库位状态拉动',
    status: '空'
  },
  {name: '库位状态推动',
    type: '推动',
    start: '库位状态推动',
    end: '库位状态推动',
    status: '空'
  }
])
const customFieldName = {
  text: 'value',
  id: 'id'
}
const list2= ref([
  { name: '扫描拉动A扫描拉动',
    type: '拉动',
    start: '扫描拉动拉动',
    end: '扫描拉动扫描拉',
    status: '空'
  },
  { name: '扫描推动A扫描推动',
    type: '推动',
    start: '起始',
    end: '目标',
    status: '空'
  }
])
const list3= ref([
  { name: '订单拉动A订单拉动',
    type: '拉动',
    start: '起始',
    end: '目标',
    status: '空'
  },
  { name: '订单拉动V',
    type: '拉动',
    start: '拉动',
    end: '目标',
    status: '空'
  }
])
const list4= ref([
  { name: '钢托号A',
    type: '拉动',
    start: '起始',
    end: '目标',
    status: '空'
  },
  { name: '钢托号B',
    type: '推动',
    start: '起始',
    end: '目标',
    status: '空'
  }
])
const finished = ref(false)
function back() {
  router.push({ path: "/index" })
}
function successSubmit() {
  // 业务下发成功
  Toast({
    'message': '业务下发成功',
    'duration': 5000
  });
}
function goDetail(v) {
  console.log(v.option, v.way)
  switch (true) {
    case (v.option === '库位状态' && v.way === '拉动'):
      router.push({ path: "/locationA" })
      break;
    case (v.option === '库位状态' && v.way === '推动'):
      router.push({ path: "/locationB" })
      break;
    case (v.option === '扫描' && v.way === '拉动'):
      router.push({ path: "/scanA" })
      break;
    case (v.option === '扫描' && v.way === '推动'):
      router.push({ path: "/scanB" })
      break;
    case (v.option === '订单' && v.way === '拉动'):
      router.push({ path: "/orderA" })
      break;
    case (v.option === '订单' && v.way === '推动'):
      router.push({ path: "/orderB" })
      break;
    case (v.option === '钢托号' && v.way === '拉动'):
      router.push({ path: "/steelA" })
      break;
    case (v.option === '钢托号' && v.way === '推动'):
      router.push({ path: "/steelB" })
      break;
    default:
      // 如果没有任何条件匹配，可以在这里处理
      console.log('没有匹配的条件');
  }
}
function goA(v) {
  if (v === '拉动') {
    router.push({ path: "/locationA" })
  } else {
    router.push({ path: "/locationB" })
  }
}
function goOrder() {
  router.push({ path: "/orderA" })
}
function goScan(v) {
  if (v === '拉动') {
    router.push({ path: "/scanA" })
  } else {
    router.push({ path: "/scanB" })
  }
}
function statusConfirm(value) {
  status.value = value.id
  statusName.value = value.value
  statusPicker.value = false
}
function jigTypeConfirm(value) {
  jigType.value = value.id
  jigTypeName.value = value.value
  jigPicker.value = false
}
function goG(v) {
  if (v === '拉动') {
    router.push({ path: "/steelA" })
  } else {
    router.push({ path: "/steelB" })
  }
}
</script>
<style scoped>
.flex{
  display: flex;
}
.scan{
  align-items: center;
}
.kan{
  justify-content: space-between;
  align-items: center;
}
</style>
<style>
.list2 .van-cell__title span{
  font-size: 0.3rem;
}
.fz {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
}
.fz div{
  width: 100px;
  display: flex;
  font-size: 0.3rem;
  margin-right: 10px;
}
.title_x{
  width: 100%;
  padding: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wuliao{
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: nowrap;
}
</style>
